@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 13px;
    height: 13px;
    border: 1px solid black; /* Light grey */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}